body {
  background-color: #161616;
}

.order-section-element {
  background-color: #1d1d1d;
  color: #f0dd31;
  padding: 10px 16px;
  margin: 8px 0;
  position: relative;
}

.inPreparation {
  background-color: #360a0a;
}

.ready {
  background-color: #0f2c0b;
}

.confirmed {
  background-color: #5d510a;
}

.order-section-element::after {
  position: absolute;
  content: "";
  height: 1px;
  bottom: 0px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  background: #f0dd31;
}

.admin-container {
  padding: 10px;
  margin-top: 68px;
  word-wrap: break-word;
}

.container-flex {
  padding: 6px;
}

.order-section-title {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
}

.fa-disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.admin-button {
  padding: 4px;
  font-size: 1.8rem;
}

.menu-section {
  background-color: #000000;
  color: #f0dd31;
  padding: 10px 16px;
  margin: 12px 0;
  position: relative;
}

.menu-section-menu-pick {
  background-color: #2e2e2e;
  color: #f0dd31;
  padding: 10px 16px;
  margin: 12px 5px;
  position: relative;
}

.menu-section-menu-pick-selected {
  background-color: #493f07;
  color: #f0dd31;
  padding: 10px 16px;
  margin: 12px 5px;
  position: relative;
}

.menu-title {
  background-color: #000000;
  font-size: 2rem;
  font-weight: 700;
}

.menu-section-title {
  font-size: 1.4rem;
  text-transform: uppercase;
}

.menu-section-title.active {
  font-size: 1.6rem;
}

.menu-active-badge {
  background-color: #218b00;
  text-align: center;
  color: white;
  font-size: 1rem;
  font-weight: 500;
}

.menu-section-list {
  margin-top: 0.6rem;
}

.menu-section-element {
  background-color: black;
  margin-bottom: 0.3rem;
  padding-top: 0.23rem;
  padding-bottom: 0.3rem;
  position: relative;
  font-size: 1rem;
}

.menu-section-element::after {
  position: absolute;
  content: "";
  height: 1px;
  bottom: -2px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  background: #f0dd31;
}

.menu-section-element .element-name {
  color: #ffffff;
  font-weight: 600;
}

.menu-section-element .element-name .element-description {
  font-size: 0.8rem;
  color: #f0dd31;
}

.menu-section-element .element-vegan {
  margin-left: 6px;
  color: #82c91e;
}

.menu-cliente {
  margin-top: 70px;
  margin-bottom: 70px;
}

.menu-icon {
  font-size: 1.2rem;
  text-align: center;
}

.menu-subtitle {
  text-align: center;
  font-size: 0.9rem;
}

.navbar-home {
  background-color: #000000;
  height: 56px;
}

.navbar-home .img img {
  margin-top: 4px;
  margin-left: 8px;
  height: 60px;
}

.navbar-home .title {
  padding-left: 8px;
  font-size: 1.4rem;
  color: #ffffff;
  font-weight: 700;
}

@media only screen and (max-width: 490px) {
  .navbar-home .title {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 420px) {
  .navbar-home .title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 380px) {
  .navbar-home .title {
    padding-left: 4px;
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 330px) {
  .navbar-home .img img {
    margin-top: 6px;
    margin-left: 4px;
    height: 48px;
  }
  .navbar-home .title {
    padding-left: 4px;
    font-size: 1rem;
  }
  .menu-icon {
    font-size: 1rem;
  }
  .menu-subtitle {
    font-size: 0.6rem;
  }
  .navbar-home {
    height: 49px;
  }
}

.allign-right-title {
  padding-right: 1rem;
  padding-top: 0.1rem;
  font-size: 1.6rem;
  color: #ffffff;
  font-weight: 700;
  text-align: right;
}

.admin-container-cucina {
  padding: 20px;
  word-wrap: break-word;
}

.scale {
  transform: scale(0.5);
}

.table-container-cucina {
  background-color: black;
  color: #f0dd31;
  padding: 6px;
  font-weight: 150;
}

.table-container-waiter-waiting {
  background-color: black;
  color: #f0dd31;
  /* padding: 10px; */
  margin-bottom: 15px;
  font-weight: 150;
  border: solid 1px #f0dd31;
  border-radius: 12px;
}

.table-container-waiter-ready {
  background-color: black;
  color: #218b00;
  /* padding: 10px; */
  margin-bottom: 15px;
  font-weight: 150;
  border: solid 1px #218b00;
  border-radius: 12px;
}

.table-container-waiter-neutral {
  background-color: black;
  color: #ffffff;
  /* padding: 10px; */
  margin-bottom: 10px;
  font-weight: 150;
  border: solid 1px #ffffff;
  border-radius: 12px;
}

.allign-left-title-cucina {
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}

.allign-left-subtitle-cucina {
  font-size: 1.2rem;
  color: #f0dd31;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  padding: 1px;
}

.allign-left-text-cucina {
  font-size: 0.9rem;
  color: #ffffff;
  font-weight: 500;
  text-align: left;
  padding: 1px;
}

.allign-right-text-cucina {
  font-size: 0.8rem;
  color: #ffffff;
  font-weight: 500;
  text-align: right;
}

.waiting-time {
  font-size: 0.8rem;
  color: #ffffff;
  font-weight: 500;
  text-align: right;
  padding: 1px;
}

.ingredients-cucina {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 500;
  text-align: left;
}

.yellow {
  color: #f0dd31;
}

.red {
  color: #ff0000;
}

.yellow-g {
  font-weight: 500;
  color: #f0dd31;
}

.sizes-pills {
  background-color: #f0dd31;
  color: #000000;
  font-size: 0.7rem;
  font-weight: 700;
  text-align: center;
  padding: 0.18rem;
}

.small-padding {
  padding: 5px;
}

.absolutley-no-padding {
  padding: 0rem;
}

.mr-ghost {
  filter: grayscale(100%);
}

.icon-picker {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 0rem;
}

.white {
  color: #ffffff;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.big {
  font-size: 2.3rem;
  font-weight: 700;
}

.submit-order {
  background-color: #f0dd31;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 8px;
  margin-right: 8px;
  height: 46px;
  line-height: 46px;
  vertical-align: middle;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  box-shadow: 0px 0px 16px #000000;
}

.qr-image {
  margin-top: 50px;
}

.sort-icon-up {
  color: #ffffff;
  margin-top: 10px;
  font-size: 2rem;
}
.sort-icon-down {
  color: #ffffff;

  font-size: 2rem;
}
.price-badge {
  font-size: 0.8rem;
  margin-top: -6px;
}
.how-many-badge {
  color: black;
}

.table-container {
  background-color: black;
  color: #f0dd31;
  padding: 6px 16px;
  margin-top: 20px;
  text-align: center;
  font-weight: 150;
}

.table-container-cl {
  background-color: rgb(36, 0, 0);
  color: #f0dd31;
  padding: 6px 16px;
  margin: 10px 10px;
  text-align: center;
  font-weight: 100;
}

/* POPUP SECTION */

#dialog_base {
  background: white;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  height: 100%;
  width: 100%;

  backdrop-filter: blur(2px);
}

#dialog_content {
  position: fixed;
  left: 0;
  right: 0;
  top: 14vh;
  margin-left: auto;
  margin-right: auto;
  width: 480px; /* Need a specific value to work */
  overflow: hidden;
  z-index: 5001;
}

.alert-box {
  color: #ffffff;
  background-color: black;
  border: solid 1px #f0dd31;
  padding: 38px 18px;
  border-radius: 12px;
}

.alert-text {
  text-align: center;
}

.no-padding {
  padding: 0rem;
}

.alert-button {
  text-align: center;
  background-color: #f0dd31;
  color: black;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 12px 26px;
  border-radius: 4px;
  cursor: pointer;
}

.alert-button-waiter {
  text-align: center;
  background-color: #f0dd31;
  color: black;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 12px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.alert-button-bill {
  text-align: center;
  background-color: #198754;
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 0.9rem;
  padding: 12px 26px;
  border-radius: 4px;
  cursor: pointer;
}

.alert-button-bill:hover {
  transform: scale(1.03);
  color: rgb(255, 255, 255);
  border-color: #198754;
  box-shadow: 0 0 20px #198754;
}

.alert-button-disabled {
  text-align: center;
  background-color: #adadad;
  color: black;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 12px 26px;
  border-radius: 4px;
  cursor: pointer;
}

.alert-button:hover {
  transform: scale(1.03);
  color: black;
  border-color: #f0dd31;
  box-shadow: 0 0 20px #f0dd31;
}

@media only screen and (max-width: 590px) {
  #dialog_content {
    width: 400px;
  }
}

@media only screen and (max-width: 482px) {
  #dialog_content {
    width: 350px;
  }
}

@media only screen and (max-width: 382px) {
  #dialog_content {
    width: 320px;
  }
}
@media only screen and (max-width: 330px) {
  #dialog_content {
    width: 280px;
  }
}
/* END POPUP SECTION */

.invert-img {
  filter: invert();
}

.admin-button .menu-icon {
  font-size: 1.8rem;
}

.admin-button .menu-subtitle {
  font-size: 0.9rem;
  font-weight: 700;
}

@media only screen and (max-width: 420px) {
  .admin-button .menu-icon {
    font-size: 1.6rem;
  }

  .admin-button .menu-subtitle {
    font-size: 0.8rem;
    font-weight: 700;
  }
}

@media only screen and (max-width: 400px) {
  .admin-button .menu-icon {
    font-size: 1.4rem;
  }

  .admin-button .menu-subtitle {
    font-size: 0.6rem;
    font-weight: 700;
  }
  .order-section-title {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 400px) {
  .admin-button .menu-icon {
    font-size: 1.4rem;
  }

  .admin-button .menu-subtitle {
    font-size: 0.6rem;
    font-weight: 700;
  }

  .order-section-title {
    font-size: 1.2rem;
  }
}

.tuttoaddestra {
  padding-right: 0;
  padding-left: 0;
}

.tuttoaddestra-text {
  text-align: end;
}

.tuttoassinistra {
  padding-right: 0;
  padding-left: 0;
  margin-top: 6px;
}

.vegan-toggle {
  font-size: 1.5rem;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.button-small {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 8px 0;
}

.transparent {
  opacity: 0.5;
}

.transparent-more {
  opacity: 0.3;
}

.prevent-hover:hover {
  transform: scale(1);
  color: black;
  border-color: #f0dd31;
  box-shadow: 0 0 0px #f0dd31;
}

.button-blink {
  transform: scale(1.03) !important;
  color: black;
  border-color: #f0dd31;
  box-shadow: 0 0 20px #f0dd31 !important;
}

.order-blink-waiting {
  color: black;
  border-color: #f0dd31;
  box-shadow: 0 0 20px #f0dd31 !important;
}

.order-blink-ready {
  color: black;
  border-color: #218b00;
  box-shadow: 0 0 20px #218b00 !important;
}

.min-h-200 {
  min-height: 200px;
}

.min-h-table-waiter {
  min-height: 50px;
}

.add-button {
  text-align: center;
  background-color: #f0dd31;
  border-color: #f0dd31;
  color: black;
  font-weight: 700;
  font-size: 0.9rem;
  max-width: 150px;
  padding: 12px 26px;
  border-radius: 4px;
  cursor: pointer;
}

.add-button:hover {
  transform: scale(1.03);
  color: black;
  border-color: #f0dd31;
  box-shadow: 0 0 20px #f0dd31;
}

.nopadding {
  padding-right: 0 !important;
  margin: 0 !important;
}
