.card {
    background-color: #212529;
  }
  
  p {
    font-size: 14px;
    line-height: 1.7;
    color: rgb(255, 255, 255);
    margin: 0
  }
  
  a {
    font-size: 14px;
    line-height: 1.7;
    color: rgb(255, 255, 255);
    margin: 0;
    transition: all .4s;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s
  }
  
  a:focus {
    outline: none !important
  }
  
  a:hover {
    text-decoration: none;
    color: #f0dd31
  }
  
  .white-link {
      font-size: 14px;
      line-height: 1.7;
      color: rgb(255, 255, 255);
      margin: 0;
      transition: all .4s;
      -webkit-transition: all .4s;
      -o-transition: all .4s;
      -moz-transition: all .4s
  }
    
  .white-link:focus {
      outline: none !important
  }
    
  .white-link:hover {
      text-decoration: none;
      color: #f0dd31
    
  }
  
  .alert {
    border-radius: 0;
    font-size: 14px;
    line-height: 30px;
    width: 100%;
    height: 30px;
  }
  
  .alert-danger {
    color: rgba(204, 7, 60, 1);
    background-color: #FFEBF0;
    padding: 0 10px 0 10px;
  
  }
  
  /* FOOTER */
  
  
  footer {
    bottom: 0px;
    width: 100%;
    height: 38px;
    line-height: 38px;
    color: #e6e6e6;
    vertical-align: middle;
    padding-top: 4px;
    text-align: center;
    position: absolute;
  }
  
  .love {
    color: #bbb;
  }
  
  /* LOGIN STYLE CSS */
  
  .login-card {
    padding: 45px;
    margin-top: 40%;
    border-radius: 50px;
  }
  
  .logo-login {
    height: 110px;
  }
  
  .login-card * {
    margin: 0;
    padding: 0;
    box-sizing: border-box
  }
  
  .login-card .alert {
    padding: 0 10px 0 10px;
  }
  
  .login-card h3 {
    width: 100%;
    text-align: center;
  }
  
  .login-box {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  
  a:link {
  text-decoration: none;
  }
  
  .copy {
  
    text-align: right;
    font-size: large;
    cursor: pointer;
  }
  
  .login-card p {
    font-size: 14px;
    line-height: 1.7;
    color: #666;
    margin: 0
  }
  
  .login-title {
    margin-top: 32px;
    margin-bottom: 45px;
    font-weight: 700;
    letter-spacing: 1.7px;
  }
  
  .description-login {
    padding-left: 1px;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 1px;
  }
  
  
  @media only screen and (max-height: 1100px) {
  
    .login-card {
      margin-top: 35%;
    }
  }
  @media only screen and (max-height: 700px) {
  
    .login-card {
      margin-top: 20%;
    }
    .login-title {
      margin-bottom: 35px;
    }
  }
  @media only screen and (max-height: 600px) {
  
    .login-card {
      margin-top: 13%;
    }
    .login-title {
      margin-bottom: 25px;
    }
    .login-card {
      padding:30px;
    }
  }
  @media only screen and (max-height: 490px) {
  
    .login-card {
      margin-top: 7%;
    }
    .login-title {
      margin-bottom: 15px;
    }
  }
  @media only screen and (max-height: 470px) {
  
    .login-card {
      margin-top: 3%;
    }
    .login-title {
      margin-bottom: 0px;
    }
    .login-card {
      padding:20px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @media only screen and (max-height: 380px) {
  
    .login-card {
      margin-top: 1%;
    }
    .login-title {
      margin-bottom: 0px;
    }
    .login-card {
      padding:15px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  
  @media only screen and (max-width: 600px) {
  
    .login-card {
      padding:50px;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  
  @media only screen and (max-width: 500px) {
  
    .login-card {
      padding:50px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  
  @media only screen and (min-width: 1200px) {
  
    .login-card {
      padding:50px;
      padding-left: 120px;
      padding-right: 120px;
    }
  }
  
  
  
  
  
  .login-card input {
    outline: none;
    
  }
  
  .login-card textarea {
    outline: none;
    border: none
  }
  
  .login-card textarea,
  .login-card input {
    
    border-color: transparent ;
  }
  
  .login-card textarea:focus,
  .login-card input:focus {
    
    border-color: transparent ;
    border-bottom: solid 2px #f0dd31;
  }
  
  
  .login-card button {
    outline: none !important;
    border: none;
  }
  
  button:hover {
    cursor: pointer
  }
  
  .wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px
  }
  
  .input100 {
    font-size: 15px;
    line-height: 1.5;
    color: #111;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 38px;
    border-radius: 0px;
    padding: 0 30px 0 30px;
    
  }
  
  .focus-input100 {
    display: block;
    position: absolute;
    border-radius: 0px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0 0;
    color: #f0dd31c2
  }
  
  .input100:focus+.focus-input100 {
    -webkit-animation: anim-shadow .5s ease-in-out forwards;
    animation: anim-shadow .5s ease-in-out forwards;
    
  }
  
  @-webkit-keyframes anim-shadow {
    to {
      box-shadow: 0 0 70px 25px;
      opacity: 0
    }
  }
  
  @keyframes anim-shadow {
    to {
      box-shadow: 0 0 70px 25px;
      opacity: 0
    }
  }
  
  .symbol-input100 {
    font-size: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 0px;
    bottom: 0;
    right: 0px;
    width: 12px;
    height: 100%;
    padding-right: 35px;
    color: #666;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    cursor: pointer;
  }
  
  .input100:focus+.focus-input100+.symbol-input100 {
    color: #f0dd31;
    padding-left: 2px
  }
  
  .symbol-input100:hover {
    color: #f0dd31;
  }
  
  .login100-form-btn {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    width: 100%;
    height: 38px;
    border-radius: 4px;
    background: #f0dd31;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
  }
  
  .login100-form-btn:hover {
    background: #dac401
  }
  
  .validate-input {
    position: relative
  }
  
  .login-box {
    font-family: 'Poppins', sans-serif;
      padding: 38px;
      margin-top: auto;
  margin-bottom: auto;
  }
  
  /* LOGIN STYLE CSS */